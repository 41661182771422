import Vue from 'vue'
import VueRouter from 'vue-router'
import http from '@/request'
import store from '@/store'
import {Yetki} from "@/yetki"

Vue.use(VueRouter)

const routes = [
  {
    path: '/islemler',
    name: 'Islemler',
    component: () => import('@/views/Admin/Islemler.vue')
  },
  {
    path: '/gonulluler',
    name: 'Gonulluler',
    component: () => import('@/views/Admin/Gonulluler.vue')
  },
  {
    path: '/retweet-like',
    name: 'RetweetLike',
    component: () => import('@/views/Admin/RetweetLike.vue')
  },
  {
    path: '/tweet-cevapla/:sablonId',
    name: 'TweetCevapla',
    component: () => import('@/views/Admin/TweetCevapla.vue')
  },
  {
    path: '/degisken-kelime-ekle-veya-duzenle/:dkId?',
    name: 'DegiskenKelimeEkleVeyaDuzenle',
    component: () => import ("@/views/Admin/DegiskenKelimeEkleVeyaDuzenle.vue")
  },
  {
    path: '/degisken-kelimeler',
    name: 'DegiskenKelimeler',
    component: () => import ("@/views/Admin/DegiskenKelimeler.vue")
  },
  {
    path: '/sablonlar',
    name: 'Sablonlar',
    component: () => import ("@/views/Admin/Sablonlar.vue")
  },
  {
    path: '/sablon-ekle-veya-duzenle/:sablonId?',
    name: 'SablonEkleVeyaDuzenle',
    component: () => import('@/views/Admin/SablonEkleVeyaDuzenle')
  },
  {
    path: '/gonullu',
    name: 'Gonullu',
    component: () => import('@/views/Gonullu.vue'),
    redirect: '/gonullu/gecmis-islemler',
    children: [
      {
        path: 'gecmis-islemler',
        name: 'GecmisIslemler',
        component: () => import('@/views/GecmisIslemler.vue')
      },
      {
        path: 'izinler',
        name: 'Izinler',
        component: () => import('@/views/Izinler.vue')
      }
    ]
  },
  {
    path: '/hosgeldiniz',
    name: 'Hosgeldiniz',
    component: () => import('@/views/Hosgeldiniz.vue')
  },
  {
    path: '/',
    name: 'App',
    component: () => import('@/App.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  try {
    if (to.name === "Hosgeldiniz")
        next()
    else {
      let res = await http.request({
        url: 'giris/GirisYapmisMi',
      })

      let kisi = res.data
      store.commit('setKisi', kisi)

      if (to.name === "App") {
        if (kisi.yetki === Yetki.ADMIN) {
          next({name: 'Islemler'})
        }
        else if (kisi.yetki === Yetki.GONULLU)
          next({name: 'Gonullu'})
      }
      next()
    }
  }
  catch(e) {
    console.log(e)
    console.log(e.response)
    if (e.response.status === 404 || e.response.status === 400) {
      next({ name: 'Hosgeldiniz'} )
      //window.location.href = process.env.VUE_APP_BASE_API_URL + "giris/twitter"
    }
  }
})

export default router
