<template>
    <div id="app">

        <b-navbar v-if="kisi.yetki === admin"
                  style="background-color: seagreen;"
                  toggleable="lg" type="dark" >
            <b-navbar variant="faded" type="light">
                <b-navbar-brand :to="{name: 'Islemler'}">
                    Hayrat Yardım
                </b-navbar-brand>
            </b-navbar>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav  align="center">
                    <b-nav-item :to="{name: 'Islemler'}">İşlemler</b-nav-item>
                    <b-nav-item :to="{name: 'Sablonlar'}">Şablonlar</b-nav-item>
                    <b-nav-item :to="{name: 'DegiskenKelimeler'}">Değişken Kelimeler</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>


        <div v-if="kisi.yetki === gonullu">
            <img src="@/assets/logo.png"
                 id="logo"
                 height="300"
                 style="margin-top: -3rem !important;"
                 alt="hayrat-aid-logo">
            <h5 style="text-align:center; margin-top: 0px;">
                Hoşgeldiniz <br>
                {{kisi.name}}
            </h5>

           <!-- <div class="feedback text-center mt-5">
                <p style="margin-bottom: 0px;">Uygulamamızı Nasıl Buldunuz?</p>
                <vue-feedback-reaction
                        :labels="['Çok kötü', 'Kötü', 'Orta', 'İyi', 'Çok İyi']"
                        v-model="feedback" />
            </div>-->
        </div>


        <div class="container my-5">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import {Yetki} from "@/yetki"
    import {mapGetters} from "vuex"
    import { VueFeedbackReaction } from 'vue-feedback-reaction';
    import { VueReactionEmoji } from 'vue-feedback-reaction';

    export default {
        name: 'app',
        data() {
            return {
                feedback: '',
                admin: Yetki.ADMIN,
                gonullu: Yetki.GONULLU
            }
        },
        computed: {
            ...mapGetters([
                'kisi'
            ])
        },
        async created() {

        },
        components: {
            VueFeedbackReaction,
            VueReactionEmoji
        }
    }

</script>

<style lang="scss">
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;

        #logo {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 100%;
            margin: 15px auto 0px;
        }
        .vue-feedback-reaction {
            flex-wrap: wrap;
        }
    }
</style>
