import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    kisi: {},
  },
  getters: {
    kisi: state => state.kisi,
  },
  mutations: {
    setKisi(state, kisi) {
      state.kisi = kisi
    }
  },
  actions: {
  },
  modules: {
  }
})
